<template>
  <span hidden />
</template>

<script>
import '@/assets/theme-dark.scss';

export default {
  name: 'theme-dark',
};
</script>

<style scoped>

</style>
